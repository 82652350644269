import { configureStore } from "@reduxjs/toolkit";
import  globalReducer from "../Redux/Reducers/globalSlice" 
import userReducer from "../Redux/Reducers/authSlice"
import itemReducer from "../Redux/Reducers/userList"
import salesReducer from "../Redux/Reducers/salesSlice"

export const store = configureStore({
  reducer: {
    globalSlice: globalReducer,
    userAuth: userReducer,
    itemAuth : itemReducer,
    salesAuth : salesReducer
  },
});
