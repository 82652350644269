import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ handlePageClick, pageCounttotal }) => {
  return (
    <ReactPaginate
      className="pagination_custom"
      breakLabel="..."
      nextLabel=">"
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      marginPagesDisplayed={2}
      pageCount={pageCounttotal}
      previousLabel="<"
      renderOnZeroPageCount={null}
    />
  );
};

export default Pagination;
