import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <>
      <div class="container">
        <div class="privacy_text">
          <h2>Privacy Policy</h2>
          <h4 className="mt-4 mb-0">
            Effective Date:<span> 1st October 2024 </span>
          </h4>
          <h4 className="mt-0 mb-2">
            Last Updated: <span> 1st October 2024 </span>
          </h4>

          <p>
            Wedged Limited ("we", "us", or "our") is committed to protecting and
            respecting your privacy. This Privacy Policy explains how we
            collect, use, disclose, and protect your personal information when
            you use our mobile application (the “App”), which provides an online
            Consumer-to-Consumer (C2C) marketplace for users in the United
            Kingdom. By using the App, you agree to the practices described in
            this Privacy Policy.
          </p>

          <h2>1. Information We Collect</h2>
          <p>
            We collect various types of personal information to provide and
            improve our services. The information we collect includes:
          </p>

          <h4>1.1 Information You Provide to Us</h4>
          <p>When you use the App, you may provide us with:</p>
          <p>
            - Account Information: When you register for an account, we collect
            your name, email address, phone number, username, and password.
          </p>
          <p>
            - Account Information: When you register for an account, we collect
            your name, email address, phone number, username, and password.
          </p>
          <p>
            - Listings and Transaction Data: When you list, buy, or sell items,
            we collect information related to those transactions, including item
            descriptions, sale prices, and communications between buyers and
            sellers.
          </p>
          <p>
            - Payment Information: If you make a transaction through the App, we
            may collect payment details such as your credit card information or
            other financial details, which may be processed by third-party
            payment processors.
          </p>

          <h4>1.2 Information We Collect Automatically</h4>
          <p>When you use the App, we automatically collect:</p>
          <p>
            - Device Information: Information about the mobile device you use to
            access the App, including your device model, operating system,
            unique device identifiers, and mobile network information.
          </p>
          <p>
            - Location Information: If you enable location services, we may
            collect your precise or approximate location based on your device
            settings.
          </p>
          <p>
            - Usage Data: Information about how you interact with the App,
            including your browsing history, clicks, page views, and the times
            and dates of your activity.
          </p>

          <h4>1.3 Cookies and Tracking Technologies</h4>
          <p>
            We use cookies and similar tracking technologies to collect
            information about your interactions with the App. For more
            information, please refer to our
            <Link to="/cookies-policy"> Cookie Policy</Link>.
          </p>

          <h2>2. How We Use Your Information</h2>
          <p>We use your personal information to:</p>
          <p>- Provide, operate, and improve the App and its features.</p>
          <p>- Facilitate transactions between buyers and sellers.</p>
          <p>
            - Communicate with you, including sending notifications, service
            updates, or promotional offers.
          </p>
          <p>- Ensure the security of the App and prevent fraud or misuse.</p>
          <p>
            - Comply with legal obligations, resolve disputes, and enforce our
            terms and conditions.
          </p>

          <h4>2.1 Marketing Communications</h4>
          <p>
            With your consent, we may send you marketing communications about
            new features, services, or promotions. You can opt-out of receiving
            these communications at any time by following the unsubscribe
            instructions included in the messages or by contacting us directly.
          </p>

          <h2>3. Sharing Your Information</h2>

          <p>
            We may share your personal information in the following
            circumstances:
          </p>
          <h4>3.1 With Other Users</h4>
          <p>
            - When you buy or sell an item, we may share necessary information,
            such as your name, contact details, and delivery information, with
            the other party to facilitate the transaction.
          </p>

          <h4>3.2 With Service Providers</h4>
          <p>
            We may share your information with third-party service providers who
            assist us with operating the App, processing payments, hosting data,
            or delivering notifications. These service providers are bound by
            contractual obligations to protect your personal data and only use
            it for the purposes we specify.
          </p>

          <h4>3.3 For Legal and Compliance Reasons</h4>
          <p>We may disclose your personal information:</p>
          <p>
            <p>
              To comply with legal obligations or respond to lawful requests by
              public authorities, including law enforcement.
            </p>
          </p>
          <p>
            - To protect and defend our rights, property, or safety, or the
            rights, property, or safety of our users or others.
          </p>

          <h2>4. Your Data Protection Rights</h2>

          <p>
            As a UK-based user, you have the following rights under the UK GDPR
            and Data Protection Act 2018:
          </p>

          <p>
            - Right to Access: You can request a copy of the personal
            information we hold about you.
          </p>
          <p>
            - Right to Rectification: You can request that we correct any
            inaccurate or incomplete information.
          </p>
          <p>
            - Right to Erasure: You can request that we delete your personal
            data, subject to certain exceptions.
          </p>
          <p>
            - Right to Restrict Processing: You can request that we restrict the
            processing of your personal data in certain circumstances.
          </p>
          <p>
            - Right to Data Portability: You can request that we transfer your
            personal data to another organisation, where technically feasible.
          </p>
          <p>
            - Right to Object: You can object to the processing of your personal
            data where we are relying on a legitimate interest.
          </p>
          <p>
            - Right to Withdraw Consent: Where we are processing your data based
            on your consent, you have the right to withdraw that consent at any
            time.
          </p>
          <p>
            To exercise any of these rights, please contact us at
            team@wedged.golf.
          </p>

          <h2>5. Data Security</h2>

          <p>
            We are committed to ensuring that your information is secure. We
            have implemented appropriate technical and organisational measures
            to protect your personal data from unauthorised access, alteration,
            disclosure, or destruction. However, no system is completely secure,
            and we cannot guarantee the absolute security of your data.
          </p>

          <h2>6. Data Retention</h2>

          <p>
            We will retain your personal information only for as long as
            necessary to fulfil the purposes outlined in this Privacy Policy, or
            as required by law. If you close your account, we may retain some of
            your information as required to meet legal obligations or resolve
            disputes.
          </p>

          <h2>7. International Data Transfers</h2>

          <p>
            We may transfer your personal data to service providers or partners
            located outside the UK. In such cases, we will ensure that
            appropriate safeguards are in place to protect your data, such as
            relying on standard contractual clauses or other lawful mechanisms
            recognised under UK law.
          </p>

          <h2>8. Children’s Privacy</h2>

          <p>
            Our App is not intended for use by children under the age of 18. We
            do not knowingly collect personal information from children. If you
            believe we have inadvertently collected such information, please
            contact us so we can take steps to delete it.
          </p>

          <h2>9. Changes to This Privacy Policy</h2>

          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page, and we will update the "Effective Date"
            at the top of this policy. If the changes are significant, we may
            notify you via email or within the App. Your continued use of the
            App after such changes signifies your acceptance of the updated
            Privacy Policy.
          </p>

          <h2>10. Contact Us</h2>

          <p>
            If you have any questions or concerns about this Privacy Policy or
            how we handle your personal data, please contact us at:
          </p>
          <p>
            <b>• Email: </b> team@wedged.golf{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
