import React from "react";

const TermsConditon = () => {
  return (
    <>
      <div class="container">
        <div class="privacy_text">
          <h2>Terms and Conditions</h2>
          <h4 className="mt-4 mb-0">
            Effective Date:<span> 1st October 2024 </span>
          </h4>
          <h4 className="mt-0 mb-2">
            Last Updated: <span> 1st October 2024 </span>
          </h4>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Wedged ("the App"), an online Consumer-to-Consumer (C2C)
            marketplace. These Terms and Conditions ("Terms") govern your use of
            the App, which is operated by Wedged Limited ("we", "us", "our"). By
            accessing or using the App, you agree to be bound by these Terms. If
            you do not agree with any part of these Terms, you must not use the
            App.
          </p>

          <h2>2. Eligibility</h2>
          <p>
            To use this App, you must be at least 18 years old or have reached
            the age of majority in your jurisdiction. By registering an account
            or using the App, you represent and warrant that you meet this
            eligibility requirement.
          </p>

          <h2>3. Account Registration and Security</h2>

          <p>
            - You must create an account to access certain features of the App.
          </p>
          <p>
            - You are responsible for maintaining the confidentiality of your
            account credentials and for all activities that occur under your
            account.
          </p>
          <p>
            - You must provide accurate and complete information during
            registration and update it as necessary to keep it accurate and
            complete.
          </p>
          <p>
            - We reserve the right to suspend or terminate your account if we
            suspect any unauthorized use or breach of these Terms.
          </p>

          <h2>4. User Conduct</h2>

          <h4>As a user of the App, you agree to:</h4>
          <p>
            - Use the App only for lawful purposes in compliance with all
            applicable laws and regulations.
          </p>

          <p>
            - Refrain from engaging in any fraudulent or deceptive activities.
          </p>
          <p>
            - Not post, upload, or share any content that is offensive,
            defamatory, discriminatory, harassing, or illegal.
          </p>
          <p>
            - Not engage in spamming, phishing, or any form of unsolicited
            communication.
          </p>
          <p>
            - Refrain from using the App to promote illegal activities, goods,
            or services.
          </p>
          <p>
            - Respect the intellectual property rights of others. We reserve the
            right to monitor user content and behaviour and may take appropriate
            action, including removing content, suspending accounts, or
            reporting violations to law enforcement authorities if necessary.
          </p>

          <h2>5. Listing and Selling Items</h2>

          <h4>
            The App provides a platform for users to list and sell their goods.
            However:
          </h4>
          <p>
            - Sellers are responsible for the accuracy of item descriptions,
            pricing, and any terms of sale.
          </p>

          <p>
            - Buyers are responsible for verifying the authenticity, quality,
            and condition of the items before purchasing.
          </p>
          <p>
            - We do not own or stock the items listed, nor do we guarantee their
            quality, legality, or fitness for a particular purpose.
          </p>

          <p>
            - Sellers and buyers are responsible for resolving any disputes that
            arise from the transaction.
          </p>
          <h4>5.1 Prohibited Items</h4>

          <p>You are not allowed to list or sell the following:</p>
          <p>- Illegal or counterfeit items.</p>
          <p>- Weapons, explosives, or hazardous materials.</p>
          <p>- Drugs, including prescription drugs.</p>
          <p>- Stolen property or any items obtained illegally.</p>
          <p>- Adult content or pornography.</p>
          <p>
            - Any other items that violate local, national, or international
            laws.
          </p>
          <p>
            We reserve the right to remove any listings that violate these rules
            or are otherwise deemed inappropriate.
          </p>

          <h2>6. Payments and Fees</h2>
          <h4>6.1 Transaction Fees:</h4>

          <p>
            We may charge fees for certain services, such as listing items,
            transaction processing, or other features. These fees will be
            clearly outlined and agreed upon at the time of the transaction.
          </p>

          <h4>6.2 Third-Party Payment Processors:</h4>
          <p>
            Payments made between buyers and sellers may be facilitated by
            third-party payment processors. You agree to comply with the terms
            and conditions of the applicable payment processor and understand
            that we are not responsible for the actions or inactions of such
            third parties.
          </p>

          <h4>6.3 Refunds and Disputes:</h4>
          <p>
            All transactions are conducted between users (buyers and sellers).
            We do not facilitate refunds or returns, and any disputes regarding
            payment or product quality must be resolved directly between the
            buyer and the seller.
          </p>

          <h2>7. Intellectual Property</h2>
          <h4>7.1 Our Intellectual Property:</h4>

          <p>
            All content, trademarks, logos, and intellectual property associated
            with the App are the property of Wedged Limited or its licensors.
            You may not use, copy, distribute, or modify any content without our
            prior written consent.
          </p>

          <h4>7.2 User-Generated Content:</h4>

          <p>
            By submitting, posting, or displaying content on the App (such as
            listings, images, or feedback), you grant us a non-exclusive,
            worldwide, royalty-free license to use, distribute, modify, display,
            and reproduce that content for the purposes of operating the App.
          </p>

          <h2>8. Limitation of Liability</h2>

          <p>
            To the fullest extent permitted by law, Wedged Limited is not liable
            for any direct, indirect, incidental, or consequential damages
            resulting from:
          </p>
          <p>- Your use of or inability to use the App.</p>
          <p>- Any transaction or communication between users</p>
          <p>
            - The accuracy, legality, or quality of items listed or sold through
            the App.
          </p>
          <p>- Any technical issues, interruptions, or errors in the App.</p>

          <h2>9. Indemnification</h2>

          <p>
            You agree to indemnify and hold harmless Wedged Limited, its
            affiliates, and its directors, officers, and employees from any
            claims, liabilities, damages, losses, and expenses, including legal
            fees, arising out of your use of the App or any violation of these
            Terms.
          </p>

          <h2>10. Termination</h2>

          <p>
            We reserve the right to suspend or terminate your account at any
            time, with or without notice, if we believe you have violated these
            Terms or engaged in unlawful or improper conduct.
          </p>
          <p>
            You may terminate your account at any time by contacting us at
            team@wedged.golf. Upon termination, you must cease all use of the
            App, and any outstanding transactions will remain your
            responsibility.
          </p>

          <h2>11. Changes to These Terms</h2>

          <p>
            We may update these Terms from time to time. When we do, we will
            notify you by posting the updated Terms on the App and updating the
            "Effective Date" at the top. Your continued use of the App after the
            changes become effective will constitute your acceptance of the
            updated Terms.
          </p>

          <h2>12. Governing Law</h2>

          <p>
            These Terms shall be governed by and construed in accordance with
            the laws of laws of England and Wales. Any disputes arising under or
            in connection with these Terms shall be subject to the exclusive
            jurisdiction of the courts of laws of England and Wales.
          </p>

          <h2>13. Contact Us</h2>

          <p>
            If you have any questions about these Terms, please contact us at:
            Email: team@wedged.golf
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsConditon;
