import React from "react";
import { useNavigate } from "react-router-dom";

export default function CommanUserNav({ id }) {
  const navigate = useNavigate();

//   console.log( window.location.pathname, "444444444444444")
  return (
    <ul>
      <li className="active">
        <button
          onClick={() => navigate(`/user-overview/${id}`)}
          className={
            window.location.pathname ===`/user-overview/${id}` ? "active" : ""
          }
        >
          Overview
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/user-list/${id}`)}
          className={
            window.location.pathname ===`/user-list/${id}` ? "active" : ""
          }
        >
          Listings
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/user-sold/${id}`)}
          className={
            window.location.pathname ===`/user-sold/${id}` ? "active" : ""
          }
        >
          Sold
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/user-purchases/${id}`)}
          className={
            window.location.pathname ===`/user-purchases/${id}` ? "active" : ""
          }
        >
          Purchases
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/usertransaction/${id}`)}
          className={
            window.location.pathname ===`/usertransaction/${id}`
              ? "active"
              : ""
          }
        >
          Transactions
        </button>
      </li>
      <li>
        <button
          onClick={() => navigate(`/user-reviews/${id}`)}
          className={
            window.location.pathname ===`/user-reviews/${id}` ? "active" : ""
          }
        >
          Reviews
        </button>
      </li>
    </ul>
  );
}
