import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { verifyOtp } from "../Redux/Actions/auth";
import { toast } from "react-toastify";

export default function Otp() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();

  const validateOtp = () => {
    const otpRegex = /^[0-9]{4}$/;
    if (!otp) {
      return "OTP is required";
    } else if (!otpRegex.test(otp)) {
      return "OTP must be a 4-digit number";
    }
    return "";
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,4}$/.test(value)) {
      setOtp(value);
      if (error) setError("");
    }
  };

  console.log(id, "55555555555");

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationError = validateOtp();
    if (validationError) {
      setError(validationError);
    } else {
      dispatch(verifyOtp({ otp, adminId: id })).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          navigate(`/change-password/${res?.payload?.data?.adminId}`);
        } else {
          toast.error(res?.payload?.message);
        }
      });
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="signin-content">
              <div>
                <div className="form-heading">
                  <h1>OTP</h1>
                  <p className="otp-heading-para">
                    We’ve sent an OTP to your registered email.
                    <span> Enter it below to continue.</span>
                  </p>
                </div>
                <div>
                  <form
                    className="signin-form d-flex flex-column"
                    onSubmit={handleSubmit}
                  >
                    <div>
                      <label className="label-form">OTP</label>
                      <div className="password-input">
                        <input
                          type="text"
                          className="input-form form-control"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={handleChange}
                          maxLength={4} // Enforce max 4 digits
                        />
                      {error && <span className="text-danger">{error}</span>}
                      </div>
                    </div>

                    <div>
                      <button type="submit" className="submit-btn">
                        Enter
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <div className="wedged-logo">
              <img
                src={require("../assets/images/wedged-logo.png")}
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
