import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

export default function Sidebar({ children }) {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const isHref = (
    pageStr,
    secondPageStr,
    thirdStr,
    fouthStr,
    fifthStr,
    sixth,
    seventh
  ) => {
    if (
      window.location.href.includes(pageStr) ||
      window.location.href.split("/").includes(secondPageStr) ||
      window.location.href.split("/").includes(thirdStr) ||
      window.location.href.split("/").includes(fouthStr) ||
      window.location.href.split("/").includes(fifthStr) ||
      window.location.href.split("/").includes(sixth) ||
      window.location.href.split("/").includes(seventh)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSignOut = async (e) => {
    e.preventDefault();
    cookies.remove("adminToken");
    sessionStorage.removeItem("adminToken");
    sessionStorage.removeItem("permission")
    navigate("/");
  };

  return (
    <>
      <div className="layout-wrapper">
        <div id="sidebar-main">
          <div className="h-100">
            <div id="sidebar-menu">
              <div className="sidebar-logo">
                <Link to="">
                  <img
                    src={require("../assets/images/App_logo.png")}
                    alt="logo"
                  />
                </Link>
              </div>
              <ul className="sidebar-list-menu list-unstyled mt-4 ">
                <div>
                  <li className={isHref("dashboard") ? "tab_active" : ""}>
                    <Link to="/dashboard">
                      <i>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_82_4735)">
                            <path
                              d="M9.99998 19V14H14V19C14 19.55 14.45 20 15 20H18C18.55 20 19 19.55 19 19V12H20.7C21.16 12 21.38 11.43 21.03 11.13L12.67 3.6C12.29 3.26 11.71 3.26 11.33 3.6L2.96998 11.13C2.62998 11.43 2.83998 12 3.29998 12H4.99998V19C4.99998 19.55 5.44998 20 5.99998 20H8.99998C9.54998 20 9.99998 19.55 9.99998 19Z"
                              fill="#A3AED0"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_82_4735">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </i>
                      <span>Dashboard</span>
                    </Link>
                  </li>

                  <li className={isHref("listings", "listing-details") ? "tab_active" : " "}>
                    <Link to="/listings" className="">
                      <i className="">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                            fill="#A3AED0"
                          />
                        </svg>
                      </i>
                      <span>Listings</span>
                    </Link>
                  </li>

                  <li className={isHref("sales") ? "tab_active" : ""}>
                    <Link to="/sales" className="">
                      <i className="">
                        <svg
                          fill="rgba(163, 174, 208, 1)"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18px"
                          height="18px"
                          viewBox="0 0 606.88 606.88"
                          stroke="rgba(163, 174, 208, 1)"
                          stroke-width="7.06877"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke="#CCCCCC"
                            stroke-width="1.213754"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g>
                                <g>
                                  <path d="M58.64,280.154h162.654c32.058,0,58.14-26.082,58.14-58.14V59.36c0-32.059-26.082-58.14-58.14-58.14H58.64 C26.582,1.22,0.5,27.301,0.5,59.36v162.654C0.5,254.072,26.582,280.154,58.64,280.154z M43.34,59.36c0-8.45,6.85-15.3,15.3-15.3 h162.654c8.45,0,15.3,6.85,15.3,15.3v162.654c0,8.45-6.85,15.3-15.3,15.3H58.64c-8.45,0-15.3-6.85-15.3-15.3V59.36z"></path>
                                  <path d="M221.294,280.654H58.64c-32.334,0-58.64-26.306-58.64-58.64V59.36C0,27.025,26.306,0.72,58.64,0.72h162.654 c32.334,0,58.64,26.306,58.64,58.64v162.654C279.934,254.348,253.628,280.654,221.294,280.654z M58.64,1.72 C26.857,1.72,1,27.577,1,59.36v162.654c0,31.783,25.857,57.64,57.64,57.64h162.654c31.783,0,57.64-25.857,57.64-57.64V59.36 c0-31.783-25.857-57.64-57.64-57.64H58.64z M221.294,237.813H58.64c-8.712,0-15.8-7.088-15.8-15.8V59.36 c0-8.712,7.088-15.8,15.8-15.8h162.654c8.712,0,15.8,7.088,15.8,15.8v162.654C237.094,230.726,230.006,237.813,221.294,237.813z M58.64,44.56c-8.161,0-14.8,6.639-14.8,14.8v162.654c0,8.161,6.639,14.8,14.8,14.8h162.654c8.161,0,14.8-6.639,14.8-14.8V59.36 c0-8.161-6.639-14.8-14.8-14.8H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,1.22H385.584c-32.059,0-58.141,26.082-58.141,58.14v162.654c0,32.058,26.082,58.14,58.141,58.14h162.654 c32.059,0,58.139-26.082,58.139-58.14V59.36C606.377,27.301,580.297,1.22,548.238,1.22z M563.537,222.014 c0,8.45-6.85,15.3-15.299,15.3H385.584c-8.449,0-15.301-6.85-15.301-15.3V59.36c0-8.45,6.852-15.3,15.301-15.3h162.654 c8.449,0,15.299,6.85,15.299,15.3V222.014z"></path>
                                  <path d="M548.238,280.654H385.584c-32.335,0-58.641-26.306-58.641-58.64V59.36c0-32.334,26.306-58.64,58.641-58.64h162.654 c32.333,0,58.639,26.306,58.639,58.64v162.654C606.877,254.348,580.571,280.654,548.238,280.654z M385.584,1.72 c-31.783,0-57.641,25.857-57.641,57.64v162.654c0,31.783,25.857,57.64,57.641,57.64h162.654c31.782,0,57.639-25.857,57.639-57.64 V59.36c0-31.783-25.856-57.64-57.639-57.64H385.584z M548.238,237.813H385.584c-8.713,0-15.801-7.088-15.801-15.8V59.36 c0-8.712,7.088-15.8,15.801-15.8h162.654c8.712,0,15.799,7.088,15.799,15.8v162.654 C564.037,230.726,556.95,237.813,548.238,237.813z M385.584,44.56c-8.161,0-14.801,6.639-14.801,14.8v162.654 c0,8.161,6.64,14.8,14.801,14.8h162.654c8.16,0,14.799-6.639,14.799-14.8V59.36c0-8.161-6.639-14.8-14.799-14.8H385.584z"></path>
                                </g>
                                <g>
                                  <path d="M58.64,605.657h162.654c32.058,0,58.14-26.08,58.14-58.139V384.864c0-32.059-26.082-58.141-58.14-58.141H58.64 c-32.058,0-58.14,26.082-58.14,58.141v162.654C0.5,579.577,26.582,605.657,58.64,605.657z M43.34,384.864 c0-8.449,6.85-15.301,15.3-15.301h162.654c8.45,0,15.3,6.852,15.3,15.301v162.654c0,8.449-6.85,15.299-15.3,15.299H58.64 c-8.45,0-15.3-6.85-15.3-15.299V384.864z"></path>
                                  <path d="M221.294,606.157H58.64C26.306,606.157,0,579.852,0,547.519V384.864c0-32.335,26.306-58.641,58.64-58.641h162.654 c32.334,0,58.64,26.306,58.64,58.641v162.654C279.934,579.852,253.628,606.157,221.294,606.157z M58.64,327.224 C26.857,327.224,1,353.081,1,384.864v162.654c0,31.782,25.857,57.639,57.64,57.639h162.654c31.783,0,57.64-25.856,57.64-57.639 V384.864c0-31.783-25.857-57.641-57.64-57.641H58.64z M221.294,563.317H58.64c-8.712,0-15.8-7.087-15.8-15.799V384.864 c0-8.713,7.088-15.801,15.8-15.801h162.654c8.712,0,15.8,7.088,15.8,15.801v162.654 C237.094,556.23,230.006,563.317,221.294,563.317z M58.64,370.063c-8.161,0-14.8,6.64-14.8,14.801v162.654 c0,8.16,6.639,14.799,14.8,14.799h162.654c8.161,0,14.8-6.639,14.8-14.799V384.864c0-8.161-6.639-14.801-14.8-14.801H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,326.724H385.584c-32.059,0-58.141,26.082-58.141,58.141v162.654c0,32.059,26.082,58.139,58.141,58.139h162.654 c32.059,0,58.139-26.08,58.139-58.139V384.864C606.377,352.806,580.297,326.724,548.238,326.724z M563.537,547.519 c0,8.449-6.85,15.299-15.299,15.299H385.584c-8.449,0-15.301-6.85-15.301-15.299V384.864c0-8.449,6.852-15.301,15.301-15.301 h162.654c8.449,0,15.299,6.852,15.299,15.301V547.519z"></path>
                                  <path d="M548.238,606.157H385.584c-32.335,0-58.641-26.306-58.641-58.639V384.864c0-32.335,26.306-58.641,58.641-58.641h162.654 c32.333,0,58.639,26.306,58.639,58.641v162.654C606.877,579.852,580.571,606.157,548.238,606.157z M385.584,327.224 c-31.783,0-57.641,25.857-57.641,57.641v162.654c0,31.782,25.857,57.639,57.641,57.639h162.654 c31.782,0,57.639-25.856,57.639-57.639V384.864c0-31.783-25.856-57.641-57.639-57.641H385.584z M548.238,563.317H385.584 c-8.713,0-15.801-7.087-15.801-15.799V384.864c0-8.713,7.088-15.801,15.801-15.801h162.654c8.712,0,15.799,7.088,15.799,15.801 v162.654C564.037,556.23,556.95,563.317,548.238,563.317z M385.584,370.063c-8.161,0-14.801,6.64-14.801,14.801v162.654 c0,8.16,6.64,14.799,14.801,14.799h162.654c8.16,0,14.799-6.639,14.799-14.799V384.864c0-8.161-6.639-14.801-14.799-14.801 H385.584z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </i>
                      <span>Sales</span>
                    </Link>
                  </li>
                  <li className={isHref("transactions") ? "tab_active" : ""}>
                    <Link to="/transactions" className="">
                      <i className="">
                        <svg
                          fill="rgba(163, 174, 208, 1)"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="18px"
                          height="18px"
                          viewBox="0 0 606.88 606.88"
                          // xml:space="preserve"
                          stroke="rgba(163, 174, 208, 1)"
                          stroke-width="6.06877"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke="#CCCCCC"
                            stroke-width="1.213754"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g>
                                <g>
                                  <path d="M58.64,280.154h162.654c32.058,0,58.14-26.082,58.14-58.14V59.36c0-32.059-26.082-58.14-58.14-58.14H58.64 C26.582,1.22,0.5,27.301,0.5,59.36v162.654C0.5,254.072,26.582,280.154,58.64,280.154z M43.34,59.36c0-8.45,6.85-15.3,15.3-15.3 h162.654c8.45,0,15.3,6.85,15.3,15.3v162.654c0,8.45-6.85,15.3-15.3,15.3H58.64c-8.45,0-15.3-6.85-15.3-15.3V59.36z"></path>
                                  <path d="M221.294,280.654H58.64c-32.334,0-58.64-26.306-58.64-58.64V59.36C0,27.025,26.306,0.72,58.64,0.72h162.654 c32.334,0,58.64,26.306,58.64,58.64v162.654C279.934,254.348,253.628,280.654,221.294,280.654z M58.64,1.72 C26.857,1.72,1,27.577,1,59.36v162.654c0,31.783,25.857,57.64,57.64,57.64h162.654c31.783,0,57.64-25.857,57.64-57.64V59.36 c0-31.783-25.857-57.64-57.64-57.64H58.64z M221.294,237.813H58.64c-8.712,0-15.8-7.088-15.8-15.8V59.36 c0-8.712,7.088-15.8,15.8-15.8h162.654c8.712,0,15.8,7.088,15.8,15.8v162.654C237.094,230.726,230.006,237.813,221.294,237.813z M58.64,44.56c-8.161,0-14.8,6.639-14.8,14.8v162.654c0,8.161,6.639,14.8,14.8,14.8h162.654c8.161,0,14.8-6.639,14.8-14.8V59.36 c0-8.161-6.639-14.8-14.8-14.8H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,1.22H385.584c-32.059,0-58.141,26.082-58.141,58.14v162.654c0,32.058,26.082,58.14,58.141,58.14h162.654 c32.059,0,58.139-26.082,58.139-58.14V59.36C606.377,27.301,580.297,1.22,548.238,1.22z M563.537,222.014 c0,8.45-6.85,15.3-15.299,15.3H385.584c-8.449,0-15.301-6.85-15.301-15.3V59.36c0-8.45,6.852-15.3,15.301-15.3h162.654 c8.449,0,15.299,6.85,15.299,15.3V222.014z"></path>
                                  <path d="M548.238,280.654H385.584c-32.335,0-58.641-26.306-58.641-58.64V59.36c0-32.334,26.306-58.64,58.641-58.64h162.654 c32.333,0,58.639,26.306,58.639,58.64v162.654C606.877,254.348,580.571,280.654,548.238,280.654z M385.584,1.72 c-31.783,0-57.641,25.857-57.641,57.64v162.654c0,31.783,25.857,57.64,57.641,57.64h162.654c31.782,0,57.639-25.857,57.639-57.64 V59.36c0-31.783-25.856-57.64-57.639-57.64H385.584z M548.238,237.813H385.584c-8.713,0-15.801-7.088-15.801-15.8V59.36 c0-8.712,7.088-15.8,15.801-15.8h162.654c8.712,0,15.799,7.088,15.799,15.8v162.654 C564.037,230.726,556.95,237.813,548.238,237.813z M385.584,44.56c-8.161,0-14.801,6.639-14.801,14.8v162.654 c0,8.161,6.64,14.8,14.801,14.8h162.654c8.16,0,14.799-6.639,14.799-14.8V59.36c0-8.161-6.639-14.8-14.799-14.8H385.584z"></path>
                                </g>
                                <g>
                                  <path d="M58.64,605.657h162.654c32.058,0,58.14-26.08,58.14-58.139V384.864c0-32.059-26.082-58.141-58.14-58.141H58.64 c-32.058,0-58.14,26.082-58.14,58.141v162.654C0.5,579.577,26.582,605.657,58.64,605.657z M43.34,384.864 c0-8.449,6.85-15.301,15.3-15.301h162.654c8.45,0,15.3,6.852,15.3,15.301v162.654c0,8.449-6.85,15.299-15.3,15.299H58.64 c-8.45,0-15.3-6.85-15.3-15.299V384.864z"></path>
                                  <path d="M221.294,606.157H58.64C26.306,606.157,0,579.852,0,547.519V384.864c0-32.335,26.306-58.641,58.64-58.641h162.654 c32.334,0,58.64,26.306,58.64,58.641v162.654C279.934,579.852,253.628,606.157,221.294,606.157z M58.64,327.224 C26.857,327.224,1,353.081,1,384.864v162.654c0,31.782,25.857,57.639,57.64,57.639h162.654c31.783,0,57.64-25.856,57.64-57.639 V384.864c0-31.783-25.857-57.641-57.64-57.641H58.64z M221.294,563.317H58.64c-8.712,0-15.8-7.087-15.8-15.799V384.864 c0-8.713,7.088-15.801,15.8-15.801h162.654c8.712,0,15.8,7.088,15.8,15.801v162.654 C237.094,556.23,230.006,563.317,221.294,563.317z M58.64,370.063c-8.161,0-14.8,6.64-14.8,14.801v162.654 c0,8.16,6.639,14.799,14.8,14.799h162.654c8.161,0,14.8-6.639,14.8-14.799V384.864c0-8.161-6.639-14.801-14.8-14.801H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,326.724H385.584c-32.059,0-58.141,26.082-58.141,58.141v162.654c0,32.059,26.082,58.139,58.141,58.139h162.654 c32.059,0,58.139-26.08,58.139-58.139V384.864C606.377,352.806,580.297,326.724,548.238,326.724z M563.537,547.519 c0,8.449-6.85,15.299-15.299,15.299H385.584c-8.449,0-15.301-6.85-15.301-15.299V384.864c0-8.449,6.852-15.301,15.301-15.301 h162.654c8.449,0,15.299,6.852,15.299,15.301V547.519z"></path>
                                  <path d="M548.238,606.157H385.584c-32.335,0-58.641-26.306-58.641-58.639V384.864c0-32.335,26.306-58.641,58.641-58.641h162.654 c32.333,0,58.639,26.306,58.639,58.641v162.654C606.877,579.852,580.571,606.157,548.238,606.157z M385.584,327.224 c-31.783,0-57.641,25.857-57.641,57.641v162.654c0,31.782,25.857,57.639,57.641,57.639h162.654 c31.782,0,57.639-25.856,57.639-57.639V384.864c0-31.783-25.856-57.641-57.639-57.641H385.584z M548.238,563.317H385.584 c-8.713,0-15.801-7.087-15.801-15.799V384.864c0-8.713,7.088-15.801,15.801-15.801h162.654c8.712,0,15.799,7.088,15.799,15.801 v162.654C564.037,556.23,556.95,563.317,548.238,563.317z M385.584,370.063c-8.161,0-14.801,6.64-14.801,14.801v162.654 c0,8.16,6.64,14.799,14.801,14.799h162.654c8.16,0,14.799-6.639,14.799-14.799V384.864c0-8.161-6.639-14.801-14.799-14.801 H385.584z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </i>
                      <span>Transactions</span>
                    </Link>
                  </li>
                  <li
                    className={
                      isHref(
                        "users",
                        "user-overview",
                        "user-list",
                        "user-sold",
                        "user-purchases",
                        "usertransaction",
                        "user-reviews"
                      )
                        ? "tab_active"
                        : ""
                    }
                  >
                    <Link to="/users" className="">
                      <i className="">
                        <svg
                          fill="rgba(163, 174, 208, 1)"
                          width="24px"
                          height="24px"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="rgba(163, 174, 208, 1)"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="M16 15.503A5.041 5.041 0 1 0 16 5.42a5.041 5.041 0 0 0 0 10.083zm0 2.215c-6.703 0-11 3.699-11 5.5v3.363h22v-3.363c0-2.178-4.068-5.5-11-5.5z"></path>
                          </g>
                        </svg>
                      </i>
                      <span>Users</span>
                    </Link>
                  </li>

                  <li className={isHref("mailing-list") ? "tab_active" : ""}>
                    <Link to="/mailing-list" className="">
                      <i className="">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 13H10C10.55 13 11 12.55 11 12V4C11 3.45 10.55 3 10 3H4C3.45 3 3 3.45 3 4V12C3 12.55 3.45 13 4 13ZM4 21H10C10.55 21 11 20.55 11 20V16C11 15.45 10.55 15 10 15H4C3.45 15 3 15.45 3 16V20C3 20.55 3.45 21 4 21ZM14 21H20C20.55 21 21 20.55 21 20V12C21 11.45 20.55 11 20 11H14C13.45 11 13 11.45 13 12V20C13 20.55 13.45 21 14 21ZM13 4V8C13 8.55 13.45 9 14 9H20C20.55 9 21 8.55 21 8V4C21 3.45 20.55 3 20 3H14C13.45 3 13 3.45 13 4Z"
                            fill="#A3AED0"
                          />
                        </svg>
                      </i>
                      <span>Mailing list</span>
                    </Link>
                  </li>
                  <li className={isHref("feedbacks") ? "tab_active" : ""}>
                    <Link to="/feedbacks" className="">
                      <i className="">
                        <svg
                          fill="rgba(163, 174, 208, 1)"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18px"
                          height="18px"
                          viewBox="0 0 606.877 606.877"
                          stroke="rgba(163, 174, 208, 1)"
                          stroke-width="6.06877"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g>
                                <g>
                                  <path d="M58.64,280.154h162.654c32.058,0,58.14-26.082,58.14-58.14V59.36c0-32.059-26.082-58.14-58.14-58.14H58.64 C26.582,1.22,0.5,27.301,0.5,59.36v162.654C0.5,254.072,26.582,280.154,58.64,280.154z M43.34,59.36c0-8.45,6.85-15.3,15.3-15.3 h162.654c8.45,0,15.3,6.85,15.3,15.3v162.654c0,8.45-6.85,15.3-15.3,15.3H58.64c-8.45,0-15.3-6.85-15.3-15.3V59.36z"></path>
                                  <path d="M221.294,280.654H58.64c-32.334,0-58.64-26.306-58.64-58.64V59.36C0,27.025,26.306,0.72,58.64,0.72h162.654 c32.334,0,58.64,26.306,58.64,58.64v162.654C279.934,254.348,253.628,280.654,221.294,280.654z M58.64,1.72 C26.857,1.72,1,27.577,1,59.36v162.654c0,31.783,25.857,57.64,57.64,57.64h162.654c31.783,0,57.64-25.857,57.64-57.64V59.36 c0-31.783-25.857-57.64-57.64-57.64H58.64z M221.294,237.813H58.64c-8.712,0-15.8-7.088-15.8-15.8V59.36 c0-8.712,7.088-15.8,15.8-15.8h162.654c8.712,0,15.8,7.088,15.8,15.8v162.654C237.094,230.726,230.006,237.813,221.294,237.813z M58.64,44.56c-8.161,0-14.8,6.639-14.8,14.8v162.654c0,8.161,6.639,14.8,14.8,14.8h162.654c8.161,0,14.8-6.639,14.8-14.8V59.36 c0-8.161-6.639-14.8-14.8-14.8H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,1.22H385.584c-32.059,0-58.141,26.082-58.141,58.14v162.654c0,32.058,26.082,58.14,58.141,58.14h162.654 c32.059,0,58.139-26.082,58.139-58.14V59.36C606.377,27.301,580.297,1.22,548.238,1.22z M563.537,222.014 c0,8.45-6.85,15.3-15.299,15.3H385.584c-8.449,0-15.301-6.85-15.301-15.3V59.36c0-8.45,6.852-15.3,15.301-15.3h162.654 c8.449,0,15.299,6.85,15.299,15.3V222.014z"></path>
                                  <path d="M548.238,280.654H385.584c-32.335,0-58.641-26.306-58.641-58.64V59.36c0-32.334,26.306-58.64,58.641-58.64h162.654 c32.333,0,58.639,26.306,58.639,58.64v162.654C606.877,254.348,580.571,280.654,548.238,280.654z M385.584,1.72 c-31.783,0-57.641,25.857-57.641,57.64v162.654c0,31.783,25.857,57.64,57.641,57.64h162.654c31.782,0,57.639-25.857,57.639-57.64 V59.36c0-31.783-25.856-57.64-57.639-57.64H385.584z M548.238,237.813H385.584c-8.713,0-15.801-7.088-15.801-15.8V59.36 c0-8.712,7.088-15.8,15.801-15.8h162.654c8.712,0,15.799,7.088,15.799,15.8v162.654 C564.037,230.726,556.95,237.813,548.238,237.813z M385.584,44.56c-8.161,0-14.801,6.639-14.801,14.8v162.654 c0,8.161,6.64,14.8,14.801,14.8h162.654c8.16,0,14.799-6.639,14.799-14.8V59.36c0-8.161-6.639-14.8-14.799-14.8H385.584z"></path>
                                </g>
                                <g>
                                  <path d="M58.64,605.657h162.654c32.058,0,58.14-26.08,58.14-58.139V384.864c0-32.059-26.082-58.141-58.14-58.141H58.64 c-32.058,0-58.14,26.082-58.14,58.141v162.654C0.5,579.577,26.582,605.657,58.64,605.657z M43.34,384.864 c0-8.449,6.85-15.301,15.3-15.301h162.654c8.45,0,15.3,6.852,15.3,15.301v162.654c0,8.449-6.85,15.299-15.3,15.299H58.64 c-8.45,0-15.3-6.85-15.3-15.299V384.864z"></path>
                                  <path d="M221.294,606.157H58.64C26.306,606.157,0,579.852,0,547.519V384.864c0-32.335,26.306-58.641,58.64-58.641h162.654 c32.334,0,58.64,26.306,58.64,58.641v162.654C279.934,579.852,253.628,606.157,221.294,606.157z M58.64,327.224 C26.857,327.224,1,353.081,1,384.864v162.654c0,31.782,25.857,57.639,57.64,57.639h162.654c31.783,0,57.64-25.856,57.64-57.639 V384.864c0-31.783-25.857-57.641-57.64-57.641H58.64z M221.294,563.317H58.64c-8.712,0-15.8-7.087-15.8-15.799V384.864 c0-8.713,7.088-15.801,15.8-15.801h162.654c8.712,0,15.8,7.088,15.8,15.801v162.654 C237.094,556.23,230.006,563.317,221.294,563.317z M58.64,370.063c-8.161,0-14.8,6.64-14.8,14.801v162.654 c0,8.16,6.639,14.799,14.8,14.799h162.654c8.161,0,14.8-6.639,14.8-14.799V384.864c0-8.161-6.639-14.801-14.8-14.801H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,326.724H385.584c-32.059,0-58.141,26.082-58.141,58.141v162.654c0,32.059,26.082,58.139,58.141,58.139h162.654 c32.059,0,58.139-26.08,58.139-58.139V384.864C606.377,352.806,580.297,326.724,548.238,326.724z M563.537,547.519 c0,8.449-6.85,15.299-15.299,15.299H385.584c-8.449,0-15.301-6.85-15.301-15.299V384.864c0-8.449,6.852-15.301,15.301-15.301 h162.654c8.449,0,15.299,6.852,15.299,15.301V547.519z"></path>
                                  <path d="M548.238,606.157H385.584c-32.335,0-58.641-26.306-58.641-58.639V384.864c0-32.335,26.306-58.641,58.641-58.641h162.654 c32.333,0,58.639,26.306,58.639,58.641v162.654C606.877,579.852,580.571,606.157,548.238,606.157z M385.584,327.224 c-31.783,0-57.641,25.857-57.641,57.641v162.654c0,31.782,25.857,57.639,57.641,57.639h162.654 c31.782,0,57.639-25.856,57.639-57.639V384.864c0-31.783-25.856-57.641-57.639-57.641H385.584z M548.238,563.317H385.584 c-8.713,0-15.801-7.087-15.801-15.799V384.864c0-8.713,7.088-15.801,15.801-15.801h162.654c8.712,0,15.799,7.088,15.799,15.801 v162.654C564.037,556.23,556.95,563.317,548.238,563.317z M385.584,370.063c-8.161,0-14.801,6.64-14.801,14.801v162.654 c0,8.16,6.64,14.799,14.801,14.799h162.654c8.16,0,14.799-6.639,14.799-14.799V384.864c0-8.161-6.639-14.801-14.799-14.801 H385.584z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </i>
                      <span>Feedback</span>
                    </Link>
                  </li>
                  <li
                    className={isHref("push-notification") ? "tab_active" : ""}
                  >
                    <Link to="/push-notification" className="">
                      <i className="">
                        <svg
                          fill="rgba(163, 174, 208, 1)"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18px"
                          height="18px"
                          viewBox="0 0 606.877 606.877"
                          stroke="rgba(163, 174, 208, 1)"
                          stroke-width="6.06877"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g>
                                <g>
                                  <path d="M58.64,280.154h162.654c32.058,0,58.14-26.082,58.14-58.14V59.36c0-32.059-26.082-58.14-58.14-58.14H58.64 C26.582,1.22,0.5,27.301,0.5,59.36v162.654C0.5,254.072,26.582,280.154,58.64,280.154z M43.34,59.36c0-8.45,6.85-15.3,15.3-15.3 h162.654c8.45,0,15.3,6.85,15.3,15.3v162.654c0,8.45-6.85,15.3-15.3,15.3H58.64c-8.45,0-15.3-6.85-15.3-15.3V59.36z"></path>
                                  <path d="M221.294,280.654H58.64c-32.334,0-58.64-26.306-58.64-58.64V59.36C0,27.025,26.306,0.72,58.64,0.72h162.654 c32.334,0,58.64,26.306,58.64,58.64v162.654C279.934,254.348,253.628,280.654,221.294,280.654z M58.64,1.72 C26.857,1.72,1,27.577,1,59.36v162.654c0,31.783,25.857,57.64,57.64,57.64h162.654c31.783,0,57.64-25.857,57.64-57.64V59.36 c0-31.783-25.857-57.64-57.64-57.64H58.64z M221.294,237.813H58.64c-8.712,0-15.8-7.088-15.8-15.8V59.36 c0-8.712,7.088-15.8,15.8-15.8h162.654c8.712,0,15.8,7.088,15.8,15.8v162.654C237.094,230.726,230.006,237.813,221.294,237.813z M58.64,44.56c-8.161,0-14.8,6.639-14.8,14.8v162.654c0,8.161,6.639,14.8,14.8,14.8h162.654c8.161,0,14.8-6.639,14.8-14.8V59.36 c0-8.161-6.639-14.8-14.8-14.8H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,1.22H385.584c-32.059,0-58.141,26.082-58.141,58.14v162.654c0,32.058,26.082,58.14,58.141,58.14h162.654 c32.059,0,58.139-26.082,58.139-58.14V59.36C606.377,27.301,580.297,1.22,548.238,1.22z M563.537,222.014 c0,8.45-6.85,15.3-15.299,15.3H385.584c-8.449,0-15.301-6.85-15.301-15.3V59.36c0-8.45,6.852-15.3,15.301-15.3h162.654 c8.449,0,15.299,6.85,15.299,15.3V222.014z"></path>
                                  <path d="M548.238,280.654H385.584c-32.335,0-58.641-26.306-58.641-58.64V59.36c0-32.334,26.306-58.64,58.641-58.64h162.654 c32.333,0,58.639,26.306,58.639,58.64v162.654C606.877,254.348,580.571,280.654,548.238,280.654z M385.584,1.72 c-31.783,0-57.641,25.857-57.641,57.64v162.654c0,31.783,25.857,57.64,57.641,57.64h162.654c31.782,0,57.639-25.857,57.639-57.64 V59.36c0-31.783-25.856-57.64-57.639-57.64H385.584z M548.238,237.813H385.584c-8.713,0-15.801-7.088-15.801-15.8V59.36 c0-8.712,7.088-15.8,15.801-15.8h162.654c8.712,0,15.799,7.088,15.799,15.8v162.654 C564.037,230.726,556.95,237.813,548.238,237.813z M385.584,44.56c-8.161,0-14.801,6.639-14.801,14.8v162.654 c0,8.161,6.64,14.8,14.801,14.8h162.654c8.16,0,14.799-6.639,14.799-14.8V59.36c0-8.161-6.639-14.8-14.799-14.8H385.584z"></path>
                                </g>
                                <g>
                                  <path d="M58.64,605.657h162.654c32.058,0,58.14-26.08,58.14-58.139V384.864c0-32.059-26.082-58.141-58.14-58.141H58.64 c-32.058,0-58.14,26.082-58.14,58.141v162.654C0.5,579.577,26.582,605.657,58.64,605.657z M43.34,384.864 c0-8.449,6.85-15.301,15.3-15.301h162.654c8.45,0,15.3,6.852,15.3,15.301v162.654c0,8.449-6.85,15.299-15.3,15.299H58.64 c-8.45,0-15.3-6.85-15.3-15.299V384.864z"></path>
                                  <path d="M221.294,606.157H58.64C26.306,606.157,0,579.852,0,547.519V384.864c0-32.335,26.306-58.641,58.64-58.641h162.654 c32.334,0,58.64,26.306,58.64,58.641v162.654C279.934,579.852,253.628,606.157,221.294,606.157z M58.64,327.224 C26.857,327.224,1,353.081,1,384.864v162.654c0,31.782,25.857,57.639,57.64,57.639h162.654c31.783,0,57.64-25.856,57.64-57.639 V384.864c0-31.783-25.857-57.641-57.64-57.641H58.64z M221.294,563.317H58.64c-8.712,0-15.8-7.087-15.8-15.799V384.864 c0-8.713,7.088-15.801,15.8-15.801h162.654c8.712,0,15.8,7.088,15.8,15.801v162.654 C237.094,556.23,230.006,563.317,221.294,563.317z M58.64,370.063c-8.161,0-14.8,6.64-14.8,14.801v162.654 c0,8.16,6.639,14.799,14.8,14.799h162.654c8.161,0,14.8-6.639,14.8-14.799V384.864c0-8.161-6.639-14.801-14.8-14.801H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,326.724H385.584c-32.059,0-58.141,26.082-58.141,58.141v162.654c0,32.059,26.082,58.139,58.141,58.139h162.654 c32.059,0,58.139-26.08,58.139-58.139V384.864C606.377,352.806,580.297,326.724,548.238,326.724z M563.537,547.519 c0,8.449-6.85,15.299-15.299,15.299H385.584c-8.449,0-15.301-6.85-15.301-15.299V384.864c0-8.449,6.852-15.301,15.301-15.301 h162.654c8.449,0,15.299,6.852,15.299,15.301V547.519z"></path>
                                  <path d="M548.238,606.157H385.584c-32.335,0-58.641-26.306-58.641-58.639V384.864c0-32.335,26.306-58.641,58.641-58.641h162.654 c32.333,0,58.639,26.306,58.639,58.641v162.654C606.877,579.852,580.571,606.157,548.238,606.157z M385.584,327.224 c-31.783,0-57.641,25.857-57.641,57.641v162.654c0,31.782,25.857,57.639,57.641,57.639h162.654 c31.782,0,57.639-25.856,57.639-57.639V384.864c0-31.783-25.856-57.641-57.639-57.641H385.584z M548.238,563.317H385.584 c-8.713,0-15.801-7.087-15.801-15.799V384.864c0-8.713,7.088-15.801,15.801-15.801h162.654c8.712,0,15.799,7.088,15.799,15.801 v162.654C564.037,556.23,556.95,563.317,548.238,563.317z M385.584,370.063c-8.161,0-14.801,6.64-14.801,14.801v162.654 c0,8.16,6.64,14.799,14.801,14.799h162.654c8.16,0,14.799-6.639,14.799-14.799V384.864c0-8.161-6.639-14.801-14.799-14.801 H385.584z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </i>
                      <span>Push Notifications</span>
                    </Link>
                  </li>
                </div>

                <div>
                  <li className={isHref("admin-settings", "create-admin") ? "tab_active" : ""}>
                    <Link to="/admin-settings" className="">
                      <i className="">
                        <svg
                          fill="rgba(163, 174, 208, 1)"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="18px"
                          height="18px"
                          viewBox="0 0 606.877 606.877"
                          // xml:space="preserve"
                          stroke="rgba(163, 174, 208, 1)"
                          stroke-width="6.06877"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g>
                                <g>
                                  <path d="M58.64,280.154h162.654c32.058,0,58.14-26.082,58.14-58.14V59.36c0-32.059-26.082-58.14-58.14-58.14H58.64 C26.582,1.22,0.5,27.301,0.5,59.36v162.654C0.5,254.072,26.582,280.154,58.64,280.154z M43.34,59.36c0-8.45,6.85-15.3,15.3-15.3 h162.654c8.45,0,15.3,6.85,15.3,15.3v162.654c0,8.45-6.85,15.3-15.3,15.3H58.64c-8.45,0-15.3-6.85-15.3-15.3V59.36z"></path>
                                  <path d="M221.294,280.654H58.64c-32.334,0-58.64-26.306-58.64-58.64V59.36C0,27.025,26.306,0.72,58.64,0.72h162.654 c32.334,0,58.64,26.306,58.64,58.64v162.654C279.934,254.348,253.628,280.654,221.294,280.654z M58.64,1.72 C26.857,1.72,1,27.577,1,59.36v162.654c0,31.783,25.857,57.64,57.64,57.64h162.654c31.783,0,57.64-25.857,57.64-57.64V59.36 c0-31.783-25.857-57.64-57.64-57.64H58.64z M221.294,237.813H58.64c-8.712,0-15.8-7.088-15.8-15.8V59.36 c0-8.712,7.088-15.8,15.8-15.8h162.654c8.712,0,15.8,7.088,15.8,15.8v162.654C237.094,230.726,230.006,237.813,221.294,237.813z M58.64,44.56c-8.161,0-14.8,6.639-14.8,14.8v162.654c0,8.161,6.639,14.8,14.8,14.8h162.654c8.161,0,14.8-6.639,14.8-14.8V59.36 c0-8.161-6.639-14.8-14.8-14.8H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,1.22H385.584c-32.059,0-58.141,26.082-58.141,58.14v162.654c0,32.058,26.082,58.14,58.141,58.14h162.654 c32.059,0,58.139-26.082,58.139-58.14V59.36C606.377,27.301,580.297,1.22,548.238,1.22z M563.537,222.014 c0,8.45-6.85,15.3-15.299,15.3H385.584c-8.449,0-15.301-6.85-15.301-15.3V59.36c0-8.45,6.852-15.3,15.301-15.3h162.654 c8.449,0,15.299,6.85,15.299,15.3V222.014z"></path>
                                  <path d="M548.238,280.654H385.584c-32.335,0-58.641-26.306-58.641-58.64V59.36c0-32.334,26.306-58.64,58.641-58.64h162.654 c32.333,0,58.639,26.306,58.639,58.64v162.654C606.877,254.348,580.571,280.654,548.238,280.654z M385.584,1.72 c-31.783,0-57.641,25.857-57.641,57.64v162.654c0,31.783,25.857,57.64,57.641,57.64h162.654c31.782,0,57.639-25.857,57.639-57.64 V59.36c0-31.783-25.856-57.64-57.639-57.64H385.584z M548.238,237.813H385.584c-8.713,0-15.801-7.088-15.801-15.8V59.36 c0-8.712,7.088-15.8,15.801-15.8h162.654c8.712,0,15.799,7.088,15.799,15.8v162.654 C564.037,230.726,556.95,237.813,548.238,237.813z M385.584,44.56c-8.161,0-14.801,6.639-14.801,14.8v162.654 c0,8.161,6.64,14.8,14.801,14.8h162.654c8.16,0,14.799-6.639,14.799-14.8V59.36c0-8.161-6.639-14.8-14.799-14.8H385.584z"></path>
                                </g>
                                <g>
                                  <path d="M58.64,605.657h162.654c32.058,0,58.14-26.08,58.14-58.139V384.864c0-32.059-26.082-58.141-58.14-58.141H58.64 c-32.058,0-58.14,26.082-58.14,58.141v162.654C0.5,579.577,26.582,605.657,58.64,605.657z M43.34,384.864 c0-8.449,6.85-15.301,15.3-15.301h162.654c8.45,0,15.3,6.852,15.3,15.301v162.654c0,8.449-6.85,15.299-15.3,15.299H58.64 c-8.45,0-15.3-6.85-15.3-15.299V384.864z"></path>
                                  <path d="M221.294,606.157H58.64C26.306,606.157,0,579.852,0,547.519V384.864c0-32.335,26.306-58.641,58.64-58.641h162.654 c32.334,0,58.64,26.306,58.64,58.641v162.654C279.934,579.852,253.628,606.157,221.294,606.157z M58.64,327.224 C26.857,327.224,1,353.081,1,384.864v162.654c0,31.782,25.857,57.639,57.64,57.639h162.654c31.783,0,57.64-25.856,57.64-57.639 V384.864c0-31.783-25.857-57.641-57.64-57.641H58.64z M221.294,563.317H58.64c-8.712,0-15.8-7.087-15.8-15.799V384.864 c0-8.713,7.088-15.801,15.8-15.801h162.654c8.712,0,15.8,7.088,15.8,15.801v162.654 C237.094,556.23,230.006,563.317,221.294,563.317z M58.64,370.063c-8.161,0-14.8,6.64-14.8,14.801v162.654 c0,8.16,6.639,14.799,14.8,14.799h162.654c8.161,0,14.8-6.639,14.8-14.799V384.864c0-8.161-6.639-14.801-14.8-14.801H58.64z"></path>
                                </g>
                                <g>
                                  <path d="M548.238,326.724H385.584c-32.059,0-58.141,26.082-58.141,58.141v162.654c0,32.059,26.082,58.139,58.141,58.139h162.654 c32.059,0,58.139-26.08,58.139-58.139V384.864C606.377,352.806,580.297,326.724,548.238,326.724z M563.537,547.519 c0,8.449-6.85,15.299-15.299,15.299H385.584c-8.449,0-15.301-6.85-15.301-15.299V384.864c0-8.449,6.852-15.301,15.301-15.301 h162.654c8.449,0,15.299,6.852,15.299,15.301V547.519z"></path>
                                  <path d="M548.238,606.157H385.584c-32.335,0-58.641-26.306-58.641-58.639V384.864c0-32.335,26.306-58.641,58.641-58.641h162.654 c32.333,0,58.639,26.306,58.639,58.641v162.654C606.877,579.852,580.571,606.157,548.238,606.157z M385.584,327.224 c-31.783,0-57.641,25.857-57.641,57.641v162.654c0,31.782,25.857,57.639,57.641,57.639h162.654 c31.782,0,57.639-25.856,57.639-57.639V384.864c0-31.783-25.856-57.641-57.639-57.641H385.584z M548.238,563.317H385.584 c-8.713,0-15.801-7.087-15.801-15.799V384.864c0-8.713,7.088-15.801,15.801-15.801h162.654c8.712,0,15.799,7.088,15.799,15.801 v162.654C564.037,556.23,556.95,563.317,548.238,563.317z M385.584,370.063c-8.161,0-14.801,6.64-14.801,14.801v162.654 c0,8.16,6.64,14.799,14.801,14.799h162.654c8.16,0,14.799-6.639,14.799-14.799V384.864c0-8.161-6.639-14.801-14.799-14.801 H385.584z"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </i>
                      <span>Admin Settings</span>
                    </Link>
                  </li>
                  <li
                    className={isHref("password-settings") ? "tab_active" : ""}
                  >
                    <Link to="/password-settings" className="">
                      <i className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="rgba(163, 174, 208, 1)"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                          </g>
                        </svg>
                      </i>
                      <span>Password Settings</span>
                    </Link>
                  </li>
                  <li className="">
                    <Link onClick={handleSignOut} className="waves-effect">
                      <i className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="rgba(163, 174, 208, 1)"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                          </g>
                        </svg>
                      </i>
                      <span>Logout</span>
                    </Link>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="main-layout">
          <div className="page-content">
            <Container fluid>{children}</Container>
          </div>
        </div>
      </div>
    </>
  );
}
