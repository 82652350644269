import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import RoutePages from "./Routes/RoutePages";
import Loader from "./components/Loader";


function App() {
  return (
    <>
      <ToastContainer limit={1} />
      <Loader />
      <RoutePages />
    </>
  );
}

export default App;
