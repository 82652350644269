import { createSlice } from "@reduxjs/toolkit";
import {
  getCategory,
  getItemDetailById,
  getListing,
  getSubCategory,
} from "../Actions/userData";

const initialState = {
  loading: false,
  itemList: [],
  categoryList: [],
  subCategoryList: [],
  itemDetailList: [],
};

const ListSlice = createSlice({
  name: "list",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListing.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.itemList = payload.data;
        } else if (!payload.success) {
        }
      })

      // ===============================================================================

      .addCase(getCategory.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.categoryList = payload.data;
        } else if (!payload.success) {
        }
      })

      //   =============================================================================

      .addCase(getSubCategory.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.subCategoryList = payload.data;
        } else if (!payload.success) {
        }
      })

      // ===================================================================================

      .addCase(getItemDetailById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.itemDetailList = payload.data;
        } else if (!payload.success) {
        }
      });
  },
});

export default ListSlice.reducer;
