import { createSlice } from "@reduxjs/toolkit";
import { getSalesData } from "../Actions/salesData";

const initialState = {
  loading: false,
  salesDataList: [],
};

const SaleSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSalesData.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.salesDataList = payload.data;
        } else if (!payload.success) {
        }
      })

  },
});

export default SaleSlice.reducer;
