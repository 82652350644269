import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import { AdminLogin } from "../Redux/Reducers/adminLogin"; // Adjust as per your action for password reset
import { forgetPassword } from "../Redux/Actions/auth";
import { toast } from "react-toastify";

export default function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({}); 
  const [touched, setTouched] = useState({ email: false });

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (touched.email) {
      if (!values.email) {
        errors.email = 'Required';
      } else if (!emailRegex.test(values.email)) {
        errors.email = 'Invalid email address';
      }
    }

    return errors;
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Clear errors if valid input is provided
    if (value && validate({ email: value }).email === undefined) {
      setErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
console.log(email, "54343rrrrrrrrrrrrrr")
    const validationErrors = validate({ email });
    if (Object.keys(validationErrors).length === 0) {

      dispatch(forgetPassword({ email })).then((res) => {
        console.log(res, "defg44444444444444")
        if(res?.payload?.status === 200) {
          toast.success(res?.payload?.message)
          navigate(`/otp/${res?.payload?.data?.adminId}`)
        } else {
          toast.error(res?.payload?.message)
        }
      })
      setErrors(validationErrors);
    }
  };

  const handleBlur = () => {
    setTouched({ email: true });
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...validate({ email }),
    }));
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="signin-content">
              <div>
                <div className="form-heading">
                  <h1>Forget Password</h1>
                  <p>Enter your email to reset your password</p>
                </div>
                <div>
                  <form className="signin-form" onSubmit={handleSubmit}>
                    <div>
                      <label className="label-form">
                        Email<span>*</span>
                      </label>
                      <div>
                        <input
                          type="email"
                          className="input-form form-control"
                          placeholder="mail@simmmple.com"
                          value={email}
                          onChange={handleEmailChange}
                          onBlur={handleBlur}
                        />
                         {errors.email && <span className="text-danger">{errors.email}</span>}
                      </div>
                     
                    </div>
                    
                    <div>
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <div className="wedged-logo">
              <img src={require("../assets/images/wedged-logo.png")} alt="Logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
