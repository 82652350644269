import React from "react";

const CookiesPolicy = () => {
  return (
    <>
      <div class="container">
        <div class="privacy_text">
          <h2>Cookies Policy </h2>
          <h4 className="mt-4 mb-0">
            Effective Date:<span> 1st October 2024 </span>
          </h4>
          <h4 className="mt-0 mb-2">
            Last Updated: <span> 1st October 2024 </span>
          </h4>

          <p>
            Thank you for using Wedged Limited ("we," "our," "us"). This Cookies
            Policy explains how we use cookies and similar technologies when you
            use our mobile phone application ("App"). By using the App, you
            agree to the use of cookies as described in this policy.
          </p>
          <h2>1. What Are Cookies?</h2>
          <p>
            Cookies are small text files that are stored on your device
            (smartphone, tablet, or computer) when you use a mobile application.
            Cookies are widely used to make apps work more efficiently and
            provide information to the owners of the app. Cookies can also
            enable certain functions within the App, such as remembering your
            preferences or managing your login session.
          </p>

          <h2>2. Types of Cookies We Use</h2>
          <p>
            We use different types of cookies to improve your experience with
            our C2C marketplace. These include:
          </p>

          <h4>a. Strictly Necessary Cookies</h4>
          <p>
            These cookies are essential for the App to function properly. They
            allow you to navigate our App and use its features, such as
            accessing secure areas or completing transactions.
          </p>
          <p>
            <b>• Examples: </b> Login authentication, security tokens.
          </p>

          <h4>b. Performance and Analytics Cookies</h4>
          <p>
            These cookies collect information about how you use our App, such as
            which pages or features you access most often. This helps us improve
            the App and understand user behaviour.
          </p>
          <p>
            <b>• Examples: </b> Tracking app performance, detecting bugs,
            understanding user interactions.
          </p>

          <h4>c. Functionality Cookies</h4>
          <p>
            These cookies allow the App to remember your choices and
            preferences, such as your username, language, or region. They
            provide enhanced and more personalized features.
          </p>
          <p>
            <b>• Examples: </b> Remembering login details, saved searches, or
            items in you have viewed.
          </p>

          <h4>d. Advertising Cookies</h4>
          <p>
            We may use advertising cookies to show you ads relevant to your
            interests, based on your interactions with the App. These cookies
            help us and third-party partners understand your interests and show
            personalized advertisements across different apps or platforms.
          </p>
          <p>
            <b>• Examples: </b> Displaying personalized ads based on your
            browsing behaviour within the App.
          </p>

          <h2>3.3. Third-Party Cookies</h2>

          <p>
            We may allow third-party service providers, such as analytics or
            advertising partners, to place cookies on your device when using our
            App. These third parties may collect information about your usage
            patterns across multiple apps or platforms to improve their services
            and deliver targeted advertisements.
          </p>
          <p>
            <b>• Examples of third-party services: </b>Google Analytics,
            Facebook Ads, or other advertising networks.
          </p>

          <h2>4. Managing Cookies</h2>

          <p>
            You have the option to manage and control the use of cookies in
            several ways:
          </p>

          <h4>a. Device Settings</h4>
          <p>
            Most mobile devices allow you to manage cookies through settings.
            You can choose to block cookies or receive a notification when a
            cookie is being set. However, disabling certain cookies may affect
            the functionality of the App.
          </p>

          <h4>b. In-App Settings</h4>
          <p>
            Some of our cookie preferences may be managed directly within the
            App. Look for privacy settings or similar options within the App to
            customize your cookie preferences.
          </p>

          <h2>5. Data Protection and Privacy</h2>
          <p>
            We respect your privacy and are committed to protecting your
            personal information. For more information on how we collect, use,
            and store your data, please refer to our Privacy Policy.
          </p>

          <h2>6. Changes to This Policy</h2>

          <p>
            We may update this Cookies Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. When we make changes, we will update the "Last
            Updated" date at the top of this policy. We encourage you to review
            this policy periodically.
          </p>

          <h2>7. Contact Us</h2>

          <p>
            If you have any questions or concerns about this Cookies Policy,
            please contact us at:
          </p>

          <p>
            <b>• Email: </b>team@wedged.golf
          </p>

          <p>
            By continuing to use our App, you agree to the placement of cookies
            on your device in accordance with this Cookies Policy. If you do not
            agree to our use of cookies, please disable them using the methods
            described above.
          </p>
        </div>
      </div>
    </>
  );
};

export default CookiesPolicy;
