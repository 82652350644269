import React from "react";

const AboutUsWedged = () => {
    return (
        <>
            <div class="container">
                <div class="privacy_text">
                    <h1>About Us Wedged </h1>
                    <p>
                        Welcome to Wedged, the UK’s leading golfer-to-golfer marketplace,
                        designed for players of all levels to buy and sell golf equipment
                        and clothing with ease. Whether you’re hunting for the latest gear
                        or looking to sell your pre-loved clubs, we’ve built a platform that
                        brings convenience, affordability, and transparency to the world of
                        golf.
                    </p>
                    <h4 className="mb-0"> What We Offer:</h4>

                    <p>
                        Golfer-to-Golfer Marketplace: Wedged is exclusively focused on the
                        UK golf community, connecting buyers and sellers directly, ensuring
                        you’re dealing with fellow enthusiasts.
                    </p>
                    <p>
                        Golf Equipment & Apparel: Explore a wide selection of new and used
                        golf clubs, balls, bags, apparel, and accessories. Whatever your
                        needs, you can find quality gear at great prices.
                    </p>

                    <p>
                        No Seller Fees: Maximize your earnings when you sell. Unlike other
                        platforms, we charge no seller fees, so you keep 100% of your
                        profits.
                    </p>
                    <p>
                        Buyer Protection: Rest easy knowing your purchases are secure. We
                        offer full buyer protection, ensuring safe and reliable transactions
                        every time.
                    </p>
                    <p>
                        -No Trade-In Quotes: Get what your gear is truly worth. Skip the
                        hassle of lowball trade-in quotes. Sell directly to other golfers
                        and set your own prices.
                    </p>

                    <p>
                        -Integrated Postage: Our streamlined postage options make shipping
                        simple. Once a sale is made, easily arrange postage through the app,
                        ensuring a hassle-free transaction from start to finish.
                    </p>

                    <h4 className="mb-0">Our Mission</h4>
                    <p>
                        At Wedged, our mission is to create a seamless and affordable
                        marketplace for UK golfers. We’ve eliminated seller fees and
                        trade-in markups to ensure golfers get the best value whether buying
                        or selling. From beginner to pro, Wedged is here to help you elevate
                        your game without breaking the bank.
                    </p>
                </div>
            </div>
        </>
    );
};

export default AboutUsWedged;