import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { useDispatch } from "react-redux";
import { createAdmin, editSubAdmin, subAdminDetails } from "../Redux/Actions/auth";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function CreateAdmin() {
  const { id } = useParams();
  const [editData, setEditData] = useState({
    name: "",
    email: "",
    role: "",
    full_access: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    email: "",
    role: "",
    full_access: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    role: Yup.string().required("Role is required"),
    full_access: Yup.boolean().required("Access level is required"),
  });

  useEffect(() => {
    if (id) {
      getDetailsApi(id);
    } else {
      setEditData(initialValues);
    }
  }, [id]);

  const getDetailsApi = async (id) => {
    try {
      const payload = { id };
      const res = await dispatch(subAdminDetails(payload));

      if (res?.payload?.status === 200) {
        setEditData(res?.payload?.data);
      } else {
        setEditData(initialValues); // Reset to initial values if no valid response
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    if (editData?._id && id) {
      const data = {
        name: values.name,
        full_access: values.full_access,
        id: editData._id,
      };
      dispatch(editSubAdmin(data)).then((res) => {
        setSubmitting(false);
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          navigate("/admin-settings");
        } else {
          toast.error(res?.payload?.data?.message);
        }
      });
    } else {
      const data = {
        name: values.name,
        email: values.email,
        role: values.role,
        full_access: values.full_access,
      };

      dispatch(createAdmin(data)).then((res) => {
        setSubmitting(false);
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          navigate("/admin-settings");
        } else {
          toast.error(res?.payload?.data?.message);
        }
      });
    }
  };

  const handleRoleChange = (e, setFieldValue) => {
    const { checked } = e.target;
    setFieldValue("role", checked ? "admin" : "");
  };

  const handleFullAccessChange = (e, setFieldValue) => {
    const { value } = e.target;
    setFieldValue("full_access", value === "true"); // Set full_access to true or false based on the value
  };
  
  return (
    <Layout>
      <Container fluid className="g-0">
        <Row>
          <Col lg={12}>
            <div className="main-title-box">
              <div className="title-head-left">
                <span> Admin</span>
                <div className="user-name-contain">
                  <div className="left-arrow-icon">
                    <Link to="/admin-settings">
                      <i className="d-flex align-items-center justify-content-center">
                        <svg
                          fill="rgba(204, 210, 227, 1)"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          // xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="20px"
                          height="20px"
                          viewBox="0 0 100 100"
                          enable-background="new 0 0 100 100"
                          // xml:space="preserve"
                          stroke="rgba(204, 210, 227, 1)"
                          stroke-width="2.5"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <path d="M33.934,54.458l30.822,27.938c0.383,0.348,0.864,0.519,1.344,0.519c0.545,0,1.087-0.222,1.482-0.657 c0.741-0.818,0.68-2.083-0.139-2.824L37.801,52.564L64.67,22.921c0.742-0.818,0.68-2.083-0.139-2.824 c-0.817-0.742-2.082-0.679-2.824,0.139L33.768,51.059c-0.439,0.485-0.59,1.126-0.475,1.723 C33.234,53.39,33.446,54.017,33.934,54.458z"></path>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </i>
                    </Link>
                  </div>
                 
                <h4> {id ? "Edit Admin" : "Create Admin"} </h4>
                </div>
              </div>
              <div className="title-pro-right">
                <Link to="#" className="waves-effect">
                  <i className="">
                    <svg
                      height="20px"
                      width="20px"
                      version="1.1"
                      id="_x32_"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      fill="rgba(163,174,208,1)"
                    >
                      <g>{/* SVG content here */}</g>
                    </svg>
                  </i>
                </Link>
                <img
                  className="align-self-end"
                  src={require("../assets/images/avatar.svg").default}
                  alt="avatar"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={5}>
            <div className="card">
              <div className="card-body details-card">
                <h4 className="wallet-heading m-0">{id ? "Edit Admin" : "Create Admin"}</h4>

                <Formik
                  initialValues={editData || initialValues}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="w-100">
                      <div className="form-floating mb-2">
                        <Field
                          type="text"
                          name="name"
                          className="form-control form-details-input"
                          placeholder="John Smith"
                        />
                        <label className="form-details-label">Name</label>
                        <ErrorMessage name="name" component="div" style={{ color: "red" }} className="error mb-2" />
                      </div>

                      <div className="card wallet-card account-card mb-0">
                        <div className="card-body">
                          <p className="m-0">Role</p>
                          <div className="checkbox-input">
                            <Field
                              type="checkbox"
                              name="role"
                              className="form-check-input"
                              onChange={(e) => handleRoleChange(e, setFieldValue)}
                            />
                            <label className="form-details-input shadow-none ps-1 pe-3">
                              Admin
                            </label>
                          </div>
                        </div>
                      </div>
                      <ErrorMessage name="role" component="div" style={{ color: "red" }} className="error mb-2" />

                      <div className="form-floating mb-2">
                        <Field
                          type="email"
                          name="email"
                          className="form-control form-details-input"
                          placeholder="Email"
                          readOnly={id ?true:false}
                        />
                        <label className="form-details-label">Email</label>
                        <ErrorMessage name="email" component="div" className="error mb-2" style={{ color: "red" }} />
                      </div>

                      <div className="card wallet-card account-card mb-0">
                        <div className="card-body">
                          <p className="m-0">Access Level</p>
                          <div className="radio-input">
                            <Field
                              type="radio"
                              name="full_access"
                              value="true" // The value should be a string
                              checked={values.full_access === true} // Use the checked prop to determine which is selected
                              onChange={(e) => handleFullAccessChange(e, setFieldValue)}
                              className="form-check-input"
                            />
                            <label className="form-details-input shadow-none ps-1 pe-3">
                              Full Access
                            </label>

                            <Field
                              type="radio"
                              name="full_access"
                              value="false" // The value should be a string
                              checked={values.full_access === false} // Use the checked prop to determine which is selected
                              onChange={(e) => handleFullAccessChange(e, setFieldValue)}
                              className="form-check-input"
                            />
                            <label className="form-details-input shadow-none ps-1 pe-3">
                              View Only
                            </label>
                          </div>
                        </div>
                      </div>

                      <ErrorMessage name="full_access" component="div" style={{ color: "red" }} className="error mb-2" />

                      <div className="schedule-btn">
                        <button type="submit" className="submit-btn" disabled={isSubmitting}>
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
