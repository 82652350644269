import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import API from "../../service/Api";

export const AdminLogin = (adminData, keepLogin) => async (dispatch) => {
  const cookies = new Cookies();
  try {
    const response = await API.post("/login-Admin", adminData);

    const { status, data } = response;
    const token = data?.data?.token;

    if (status === 200 && token) {
      sessionStorage.setItem("adminToken", token);
      sessionStorage.setItem("permission", data?.data?.user?.full_access);
      
      if (keepLogin) {
        cookies.set("adminToken", token, {
          path: "/",
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
        });
      }

      window.location.href = "/dashboard";

    } else {
      toast.error(data.message || "Login failed.");
    }
  } catch (error) {
    toast.warning(error.message || "An error occurred during login.");
  }
};
