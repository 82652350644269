import React from 'react'

function NoData() {
  return (
    <div className='no-data-found'>
      <p>No Data</p>
    </div>
  )
}

export default NoData
