
import { useDispatch } from "react-redux";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { resetPassword } from "../Redux/Actions/auth";
import { toast } from "react-toastify";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [eye, setEye] = React.useState(false);
  const [eye1, setEye1] = React.useState(false);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(10, "Password cannot exceed 10 characters"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });


  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {

      dispatch(resetPassword({ newPassword: values.password, adminId: id })).then((res) => {
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          navigate(`/`);
        } else {
          toast.error(res?.payload?.message);
        }
      });     
      resetForm();
    },
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="signin-content">
              <div>
                <div className="form-heading">
                  <h1>Change Password</h1>
                  <p>Update your password below.</p>
                </div>
                <div>
                  <form className="signin-form" onSubmit={formik.handleSubmit}>
                    <div>
                      <label className="label-form">
                        New Password<span>*</span>
                      </label>
                      <div className="password-input m-0">
                        <input
                          type={eye ? "text" : "password"}
                          className="input-form form-control password-input"
                          placeholder="Min. 8 characters"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div onClick={() => setEye(!eye)}>
                          <img
                            src={require("../assets/images/eye.svg").default}
                            alt="Toggle visibility"
                            className="imgage"
                          />
                        {formik.touched.password && formik.errors.password && (
                          <span className="text-danger">{formik.errors.password}</span>
                        )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <label className="label-form">
                        Confirm Password<span>*</span>
                      </label>
                      <div className="password-input m-0">
                        <input
                          type={eye1 ? "text" : "password"}
                          className="input-form form-control password-input"
                          placeholder="Confirm your password"
                          name="confirmPassword"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div onClick={() => setEye1(!eye1)}>
                          <img
                            src={require("../assets/images/eye.svg").default}
                            alt="Toggle visibility"
                            className="imgage"
                          />
                           {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                          <span className="text-danger">{formik.errors.confirmPassword}</span>
                        )}
                        </div>
                       
                      </div>
                    </div>

                    <div>
                      <button type="submit" className="submit-btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <div className="wedged-logo">
              <img src={require("../assets/images/wedged-logo.png")} alt="Logo" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
