import { createSlice } from "@reduxjs/toolkit";
import { getUserList, listSubAdminOrUser, userDetailById, userListingById } from "../Actions/auth";

const initialState = {
    loading: false,
    userList: [],
    userDetail: [],
    listOfAdminOrSubAdmin: [],
    userListingDetail:[]
}

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, { payload }) => {
      if (payload?.success) {
        state.success = true;
        state.userList = payload.data;
      } else if (!payload.success) {
      }
    })

    // ==============================================================================

    .addCase(userDetailById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.userDetail = payload.data;
        } else if (!payload.success) {
        }
      })

    //   ==========================================================================

    .addCase(listSubAdminOrUser.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.listOfAdminOrSubAdmin = payload.data;
        } else if (!payload.success) {
        }
      })

      // =============================================================================

      .addCase(userListingById.fulfilled, (state, { payload }) => {
        if (payload?.success) {
          state.success = true;
          state.userListingDetail = payload.data;
        } else if (!payload.success) {
        }
      })

  },
});

export default UserSlice.reducer;