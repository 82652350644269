import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { AdminLogin } from "../Redux/Reducers/adminLogin";
import Cookies from "universal-cookie";

export default function SignIn() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [eye, setEye] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({ email: false, password: false });

  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validate email
    if (touched.email) {
      if (!values.email) {
        errors.email = "Required";
      } else if (!emailRegex.test(values.email)) {
        errors.email = "Invalid email address";
      }
    }

    // Validate password
    if (touched.password) {
      if (!values.password) {
        errors.password = "Required";
      } else if (values.password.length < 8 || values.password.length > 10) {
        errors.password = "Password must be between 8 and 10 characters";
      }
    }

    return errors;
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    // Clear errors if valid input is provided
    if (
      value &&
      validate({ ...{ email: value }, password }).email === undefined
    ) {
      setErrors((prevErrors) => ({ ...prevErrors, email: undefined }));
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    // Clear errors if valid input is provided
    if (value && validate({ email, password: value }).password === undefined) {
      setErrors((prevErrors) => ({ ...prevErrors, password: undefined }));
    }
  };
  console.log(errors, "sdfs324234234");

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validate({ email, password });
    if (Object.keys(validationErrors).length === 0) {
      let obj = {
        email: email,
        password: password,
      };
      dispatch(AdminLogin(obj, rememberMe));
    } else {
      setErrors(validationErrors);
    }
  };

  const handleBlur = (field) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...validate({ email, password }),
    }));
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="signin-content">
              <div>
                <div className="form-heading">
                  <h1>Sign In</h1>
                  <p>Enter your email and password to sign in!</p>
                </div>
                <div>
                  <form className="signin-form" onSubmit={handleSubmit}>
                    <div>
                      <label className="label-form">
                        Email<span>*</span>
                      </label>
                      <div>
                        <input
                          type="email"
                          className="input-form form control"
                          placeholder="mail@simmmple.com"
                          value={email}
                          onChange={handleEmailChange}
                          onBlur={() => handleBlur("email")}
                        />
                        <div>
                          {" "}
                          {errors.email && (
                            <span className="text-danger">{errors.email}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <label className="label-form">
                        Password<span>*</span>
                      </label>
                      <div className="password-input">
                        <input
                          type={eye ? "text" : "password"}
                          className="input-form form control password-inpu"
                          placeholder="Min. 8 characters"
                          onChange={handlePasswordChange}
                          onBlur={() => handleBlur("password")}
                          value={password}
                        />
                        <div>
                          {" "}
                          {errors.password && (
                            <span className="text-danger">
                              {errors.password}
                            </span>
                          )}{" "}
                        </div>
                        <div onClick={() => setEye(!eye)}>
                          <img
                            src={require("../assets/images/eye.svg").default}
                            alt=""
                            className="imgage"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="logged-in-forget ">
                      <div className="logged-in ">
                        <input
                          type="checkbox"
                          className=""
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <label className=""> Keep me logged in</label>
                      </div>
                      <div>
                        <Link to="/forget-password" className="forget-password">
                          Forget password?
                        </Link>
                      </div>
                    </div>
                    <div>
                      {/* <Link to="/otp">
                        <button to="/otp" type="submit " className="submit-btn">
                          Sign In
                        </button>
                      </Link> */}
                      {false ? (
                        <button type="submit" className="submit-btn" disabled>
                          Signing In...
                        </button>
                      ) : (
                        <button type="submit" className="submit-btn">
                          Sign In
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <div className="wedged-logo">
              <img src={require("../assets/images/wedged-logo.png")} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
